import { Suspense, React } from "react";
import { Route, Routes } from "react-router-dom";
import { routes } from "./Routes";
import PageNotFound from "../pages/404";

const AppRoutes = () => {

  return (
    <>
      <Suspense>
        <Routes>
          {routes &&
            routes.length > 0 &&
            routes.map((route, index) => (
              <Route
                path={route?.path}
                key={"route_" + index}
                element={<route.element />}
              />
            ))}

          <Route path="/*" element={<PageNotFound />} />
        </Routes>
      </Suspense>
    </>
  );
};

export default AppRoutes;
