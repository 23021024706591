import React, { useState } from "react";

import "./App.css";
import "../src/comman/index.css";
import "./pages/index.css";
import "../src/index.css";
import { BrowserRouter as Router } from "react-router-dom";
import theme from "./theme";
import { ThemeProvider } from "@mui/material";
import AppRoutes from "./routes";
import { SnackbarProvider } from "notistack";
const App = () => {
  return (
    <Router>
      <SnackbarProvider
        maxSnack={3}
        autoHideDuration={3000}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <ThemeProvider theme={theme}>
          <AppRoutes />
        </ThemeProvider>
      </SnackbarProvider>
    </Router>
  );
};

export default App;
