import { lazy } from "react";
const JSONViewer = lazy(() => import("../pages/JSONViewer"));
const InsuranceForm = lazy(() => import("../pages/insurance"));

export const routes = [
  
  {
    element: InsuranceForm,
    path: "/insurance",
    // isPrivate: true,
    // isLayout: false,
    // isView: true,
    // isFooter: true,
    // isHeader: false,
  },
  {
    element: JSONViewer,
    path: "/",
    // isPrivate: false,
    // isLayout: true,
    // isView: true,
    // isFooter: false,
    // isHeader: false,
  },
];
